// "use client";
import { AccordionT, DesignSystemComponent } from "@/types/design-system-types";
import { cn } from "@/utils/helpers";
import ComponentResolver from "@/utils/system/component-resolver";
import Page from "@/utils/system/page";
import { ChevronDown } from "lucide-react";
import { useEffect, useRef, useState } from "react";
import RichText from "../atoms/rich-text";

const Accordion = ({
  data, // All incoming Contentful data
  debug, // Debug framer

  // Contentful controlled Props
  title = data?.fields.title || "",
  content = data?.fields?.content,
  openByDefault = data?.fields.openByDefault ?? false,
  // theme = data?.fields?.theme || "white-100",
  theme,

  // Custom Props
  isFAQ = false,
  className,
  shouldAnimate = true,

  ...props
}: DesignSystemComponent<AccordionT>) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const innerContentRef = useRef<HTMLDivElement>(null);

  const [isOpen, setIsOpen] = useState(openByDefault);
  const [maxHeight, setMaxHeight] = useState("0px");
  const [overflowHidden, setOverflowHidden] = useState(true);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
    setMaxHeight(isOpen ? "0px" : `${contentRef?.current?.scrollHeight}px`);
    if (isOpen) setOverflowHidden(true);
  };

  useEffect(() => {
    if (openByDefault) setIsOpen(true);
  }, [setIsOpen, openByDefault]);

  // Overflow hidden property changes because the debugger needs to overflow
  useEffect(() => {
    if (isOpen) {
      setMaxHeight(`${contentRef?.current?.scrollHeight}px`);
      // Delay removing overflow hidden to allow for the animation to complete
      setTimeout(() => {
        setOverflowHidden(false);
      }, 175);
    } else {
      setMaxHeight("0px");
    }
  }, [isOpen]);

  const themeClass = Page.getThemeClass(theme);

  // @ts-ignore
  const isContentDynamic = content?.sys || content?.data;

  return (
    <div
      {...props}
      className={cn(
        "relative w-full rounded-xl border-[1px] border-solid border-gray-100 n-accordion",
        [
          isOpen ? "shadow-xl" : "shadow-sm",
          themeClass,
          className,
          shouldAnimate && "transition-all duration-500",
        ]
      )}
      data-default-open={openByDefault}
      data-open={isOpen}
    >
      {debug}
      <div
        className="flex w-full cursor-pointer items-center justify-between px-4 py-3.5 gap-x-1"
        onClick={toggleAccordion}
      >
        <p className="mb-0 font-bold shrink basis-[fit-content] n-accordion-title">
          {title}
        </p>
        <ChevronDown
          height={16}
          className={`transform transition-transform basis-[20px] ${
            isOpen ? "rotate-180" : "rotate-0"
          }`}
        />
      </div>
      <div
        ref={contentRef}
        style={{ maxHeight }}
        className={cn(
          "px-1 ease-in-out",
          overflowHidden ? "overflow-hidden" : "",
          shouldAnimate && "transition-max-height duration-500"
        )}
      >
        <div ref={innerContentRef} className="px-3 pb-3.5 pt-2">
          {/* Static content passed as a prop */}
          {!isContentDynamic && content}

          {/* Dynamic content and accordion */}
          {isContentDynamic && !isFAQ && <ComponentResolver data={content} />}

          {/* Dynamic content and accordion is FAQ */}
          {isContentDynamic && isFAQ && (
            <>
              <RichText data={content as any} sys={data?.sys} fieldName="answer" />

              {process.env.ENV !== "PROD" && (
                <div className="w-full">
                  <div className="ml-auto mt-4 flex w-min items-center gap-1">
                    <p className="font-base mb-0 ml-auto whitespace-nowrap rounded-md bg-navy-100 px-1.5 py-0.5 text-xxs text-white">
                      {/* @ts-ignore */}
                      {data?.fields?.category}
                    </p>
                    {/* @ts-ignore */}
                    {Array.isArray(data?.fields?.Topics?.tags) &&
                      // @ts-ignore
                      data?.fields?.Topics?.tags?.map((t, i) => {
                        return (
                          <p
                            className="font-base mb-0 ml-auto whitespace-nowrap rounded-md bg-azure-100 px-1.5 py-0.5 text-xxs text-white"
                            key={i}
                          >
                            {t}
                          </p>
                        );
                      })}
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Accordion;
